import React from "react";

import styles from "../../styles/modal.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { SwitchModalType } from "../../interfaces";

interface Props {
  closeModal: () => void;
  switchModal: (modal: SwitchModalType) => void;
}

export const SignInForm: React.FC<Props> = ({ closeModal, switchModal }) => {
  const switchModalHandlerReister = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault();
    switchModal("register");
  };
  const switchModalHandlerForgot = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault();
    switchModal("forgot");
  };
  return (
    <div className={styles.modal}>
      <div className={styles.header}>
        <h2>Enter your info</h2>
        <div className={styles.close} onClick={closeModal}>
          <FontAwesomeIcon icon={faTimes} />
        </div>
      </div>
      <div className={styles.form}>
        <form
          id="login"
          action="https://wager.wagerattack.com/redirectlogin.php"
          method="post"
        >
          <input
            name="username"
            type="text"
            placeholder="USER"
            aria-label="USER"
            id="userInputLog"
            required
          />
          <input
            type="password"
            name="password"
            id="password"
            placeholder="PASSWORD"
            aria-label="PASSWORD"
            required
          />
          <input
            type="hidden"
            name="multiaccount"
            id="multiaccount"
            value="1"
          />
          <button type="submit">Login</button>
          <a href="/#" onClick={switchModalHandlerForgot}>
            Forgot your ID / Password?
          </a>
        </form>
        <div className={styles.change}>
          <h3>New user</h3>
          <a href="/#" onClick={switchModalHandlerReister}>
            Register now
          </a>
        </div>
        <div className={styles.demo}>
          <form
            id="login"
            action="https://wager.wagerattack.com/redirectlogin.php"
            method="post"
          >
            <input type="hidden" name="username" value="Wm313" />
            <input type="hidden" name="password" value="test20" />
            <input
              type="hidden"
              name="multiaccount"
              id="multiaccount"
              value="1"
            />
            <button type="submit" className={styles.btn_demo}>
              Demo Account
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};
