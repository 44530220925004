import React from "react";
import styles from "../../styles/header.module.scss";
import { AuthContainerComponent } from "./AuthContainer.component";
import { PaymentMethodsComponent } from "./PaymentMethods.component";
import { SocialsMobileComponent } from "./SocialsMobile.component";
import { SwitchModalType } from "../../interfaces";

interface Props {
  openModal: (modal: SwitchModalType) => void;
}

export const HeaderComponent: React.FC<Props> = ({ openModal }) => {
  return (
    <header className={styles.header}>
      <div className={styles["header-container"]}>
        <div className={styles["header-container__logo"]}>
          <img src="/assets/img/logo.png" alt="Wager Attack Logo" />
        </div>
        <div className={styles["header-container__options"]}>
          <div className={`${styles.option} ${styles["option--auth"]}`}>
            <AuthContainerComponent openModal={openModal} />
          </div>
          <div className={styles.socialDesktop}>
            <SocialsMobileComponent />
          </div>
        </div>
      </div>
      <div className={`${styles.option} ${styles["option--pay-methods"]}`}>
        <PaymentMethodsComponent />
      </div>
    </header>
  );
};
