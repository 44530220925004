import "./styles.scss";
import React from "react";

import LayoutContainer from "../../layouts/container";
import CryptoStepOne from "../../components/crypto/step-one";
import CryptoStepTwo from "../../components/crypto/step-two";
import CryptoStepThree from "../../components/crypto/step-three";

const CryptoPage = () => {
  return (
    <LayoutContainer>
      <main className="crypto">
        <div className="crypto_hero">
          <div className="crypto_hero__divider">
            <div className="crypto_hero__content max-container">
              <h2>
                switch to a <br />
                <span>Crypto account</span>
              </h2>
              <img src="/assets/img/faster-payouts.svg" alt="faster-payouts" />
              <button>Withdraw Any Day</button>
            </div>
            <div className="crypto_hero__footer">
              <div className="crypto_hero__footer_content">
                <img src="/assets/img/cash-bonus.svg" alt="cash-bonus" />
                <div className="flex gap:2 items:center">
                  <span className="bonus">Up to $5000 a Week</span>
                  <span>•</span>
                  <span className="bonus">Automatic Deposits</span>
                </div>
              </div>
            </div>
          </div>
          <div className="crypto-logo">
            <img src="/assets/img/crypto-logo.svg" alt="crypto-logo" />
          </div>
        </div>

        {/* CRYPTOCURRENCIES */}
        <div className="crypto-hero">
          <div className="crypto-hero wrap">
            <h2>
              HOW TO DEPOSIT WITH <span>CRYPTOCURRENCIES</span>
            </h2>
            <div className="crypto-hero_content">
              <p>
                This tutorial will guide you to deposit Bitcoin or any of our
                featured altcoins from your secure digital wallet to your Wager
                Attack account!
              </p>
              <p>
                Already know how to deposit with cryptocurrencies?{" "}
                <a href="">Click here</a>{" "}
              </p>
            </div>
          </div>
        </div>
        <div className="crypto_steps">
          <CryptoStepOne />
          <CryptoStepTwo />
          <CryptoStepThree />
        </div>
        <div className="crypto_exchange">
          <div className="flex flex:column gap:6 crypto_exchange__content">
            <h2>
              <span>Get Started</span> - Choose Exchange
            </h2>
            <div className="exchange-wrap_img">
              <img
                className="exchange-img"
                src="/assets/img/wager/coinbase.png"
                alt="coinbase"
              />
              <img
                className="exchange-img"
                src="/assets/img/wager/kraken.png"
                alt="kraken"
              />
              <img
                className="exchange-img"
                src="/assets/img/wager/crypto.png"
                alt="crypto"
              />
              <img
                className="exchange-img"
                src="/assets/img/wager/gemini.png"
                alt="gemini"
              />
            </div>
            <p>
              You can also buy crypto currency via{" "}
              <strong className="highlight">CashApp</strong> and other payment
              services <br /> <small><strong>*Associated with higher fees*</strong></small>
              <br />
              Or using cash at a{" "}
              <strong className="highlight">Crypto ATM.</strong>
            </p>
            {/* <button>GO TO CASHIER</button> */}
          </div>
        </div>
        <div className="crypto_footer">
          <h4 className="text:center">Still have questions? Contact us via Live Chat</h4>
        </div>
      </main>
    </LayoutContainer>
  );
};

export default CryptoPage;
