import React from "react";

import { SwitchModalType } from "../interfaces";
import { SignInForm } from "../components/ui/SignInForm.component";
import { HeaderComponent } from "../components/ui/Header.component";
import { ForgotFormComponent } from "../components/ui/ForgotForm.component";
import { RegisterFormComponent } from "../components/ui/RegisterForm.component";

interface Props {
  children: React.ReactNode;
  switchModal?: SwitchModalType;
}

function LayoutContainer({ children, switchModal }: Readonly<Props>) {
  const [openModal, setOpenModal] = React.useState(false);
  const [modalLogin, setModalLogin] = React.useState(true);
  const [, setForgotPasswordModal] = React.useState(false);
  const [registerModal, setRegisterModal] = React.useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const aff = urlParams.get("aff");

  React.useEffect(() => {
    if (aff) {
      handlerSwitchModal("register");
    }
  }, [aff]);

  React.useEffect(() => {
    if (switchModal) handlerSwitchModal(switchModal);
  }, [switchModal]);

  const handlerSwitchModal = (modal: SwitchModalType) => {
    if (modal === "login") {
      setModalLogin(true);
      setForgotPasswordModal(false);
      setRegisterModal(false);
    } else if (modal === "forgot") {
      setModalLogin(false);
      setForgotPasswordModal(true);
      setRegisterModal(false);
    } else if (modal === "register") {
      setModalLogin(false);
      setForgotPasswordModal(false);
      setRegisterModal(true);
    }
    setOpenModal(true);
  };

  return (
    <div className={`App ${openModal ? "modal-active" : ""}`}>
      <HeaderComponent openModal={handlerSwitchModal} />
      {children}
      <div className={`modal ${openModal ? "modal--active" : ""}`}>
        <div className="overlay">
          <div className="modal-card">
            {modalLogin ? (
              <SignInForm
                closeModal={() => setOpenModal(false)}
                switchModal={handlerSwitchModal}
              />
            ) : registerModal ? (
              <RegisterFormComponent
                closeModal={() => setOpenModal(false)}
                switchModal={handlerSwitchModal}
              />
            ) : (
              <ForgotFormComponent
                closeModal={() => setOpenModal(false)}
                switchModal={handlerSwitchModal}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LayoutContainer;
